import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Loader from "../Loader";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const DocumentPreview = ({ file }) => {
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  return (
    <Document
      file={file}
      onClick={() => window.open(file.url, "_blank")}
      style={{
        cursor: "pointer",
      }}
      onLoadError={(err) => console.log(err)}
      onLoadSuccess={() => setIsDocumentLoaded(true)}
      loading={<Loader />}
    >
      {isDocumentLoaded && <Page pageIndex={0} pageNumber={1} height={80} />}
    </Document>
  );
};

export default DocumentPreview;
